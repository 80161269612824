<template>
    <div style="padding:10px;">

        <van-grid clickable :column-num="2">
            <van-grid-item icon="logistics" text="车辆录入" to="/car" />
            <van-grid-item icon="friends-o" text="本地数据" to="/data" />
        </van-grid>

        <van-tabbar>
            <van-button round block type="warning" style="margin: 10px;" size='small' @click="logout">
                退出登录
            </van-button>
        </van-tabbar>
    </div>
</template>

<script>

    // import Vconsole from 'vconsole';
    // new Vconsole();
    
    import { Grid, GridItem, Button, Tabbar } from "vant";

    export default {
        components: {
            vanGrid: Grid,
            vanGridItem: GridItem,
            vanButton: Button,
            vanTabbar: Tabbar,
        },
        methods: {
            logout() {
                this.$store.dispatch('LOGOUT')
                this.$router.replace(
                    {
                        name: 'login',
                    }
                )
            }
        }
    };
</script>